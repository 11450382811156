import React from "react"
import {Link} from 'gatsby'
import Layout from "../components/layouts/Layout"
import { motion } from "framer-motion"
import {Helmet} from 'react-helmet'

const variants = {
  hidden: { opacity: 0.2 },
  visible: { opacity: 1 },
}

const header = () => (
  <div className={`bg-gray-100`}>
    <div className="w-full max-w-screen-xl mx-auto px-6 pb-8">
      <div className="flex flex-wrap flex-row">
        <div className="w-full flex">
          <motion.div 
            className="pt-12 text-left"
            initial="hidden"
            animate="visible"
            variants={variants}
            >
            <h1 className="text-4xl text-black" style={{maxWidth: 420+'px', lineHeight: 60+'px', marginBottom: 20}}>Page not found.</h1>
            <p className="text-xl text-gray-800 font-normal max-w-md ">
            </p>
          </motion.div>
        </div>
      </div>
      
    </div>
  </div>
)

export default ({data}) => <div>
  <Layout header={header()}>
      <Helmet>
        <title>404 Not Found</title>
        <meta name="description" content="CounterSet is a platform for smart packaging built in South Australia." />
        <meta property="og:description" content="CounterSet is a platform for smart packaging built in South Australia." />
      </Helmet>
      <p className="mt-6"></p>

    <div className={`flex sm:flex-row flex-wrap my-12`}>
          <div className="flex items-center">
              <div className={`pt-4`}>
                  <h3 className="font-bold text-2xl mb-2">Sorry, we can't find that page.</h3>
                  <div className="text-lg pb-4">
                      Return to the homepage <Link className="text-indigo-500" to="/">here.</Link><br />
                  </div>
              </div>
          </div>
    </div>
  </Layout>
</div>
